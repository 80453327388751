
.radioheart-widgets-wrapper {
  font-family: "Museo Sans", sans-serif;
}
.fade-enter-active, .fade-leave-active {
  -webkit-transition: opacity .5s;
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.widget__modal {
  font-family: 'Museo Sans', sans-serif;
  position: fixed;
  width: 100%;
  height: calc(100vh - 76px);
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 999999;
}
.widget__modal-background {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.widget__modal__content {
  background: #FFFFFF;
  -webkit-box-shadow: 0px 0px 64px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 0px 64px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  width: 95%;
  max-width: 700px;
  max-height: 80%;
  position: relative;
}
@media (min-width: 576px) {
.widget__modal__content {
      width: 75%;
}
}
.widget__modal__content__close {
  position: absolute;
  top: 16px;
  right: 16px;
  color: #CACACA;
  cursor: pointer;
}
.widget__modal__content__close__icon:hover .widget__modal__content__close__icon__path {
  fill: var(--primary-color);
}
.widget__modal__content__close__icon:hover .widget__modal__content__close__icon__path {
  fill: var(--primary-color);
}
.widget__modal__content__close__icon__path {
  fill: #CACACA;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.widget-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 16px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media (min-width: 992px) {
.widget-content {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
}
}
.widget-content__block-item {
  background: #FFFFFF;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 12px;
  padding: 32px 16px;
  color: #000000;
}
@media (min-width: 576px) {
.widget-content__block-item {
      padding: 32px;
}
}
.widget-content__block-title {
  text-align: center;
  font-size: 16px;
  line-height: 29px;
  margin-bottom: 16px;
  font-weight: 700;
}
@media (min-width: 576px) {
.widget-content__block-title {
      font-size: 24px;
}
}
.widget-content__block-title__text {
  color: #888888;
  cursor: pointer;
  margin-right: 8px;
  -webkit-transition: 0.5s all;
  transition: 0.5s all;
  font-weight: 500;
}
@media (min-width: 576px) {
.widget-content__block-title__text {
      margin-right: 16px;
}
}
.widget-content__block-title__text:last-child {
    margin-right: 0px;
}
.widget-content__block-title__text--active {
  color: #000000;
  font-weight: 700;
}
.widget-content__block-inside {
  max-height: 50vh;
  overflow-y: auto;
  padding-right: 16px;
}
.widget-content__block-inside::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}
.widget-content__block-inside::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #CACACA;
}

.widget__songs-list {
  text-align: center;
}
.widget-button {
  height: 36px;
  border: 1px solid #888888;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  min-width: 140px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
  -webkit-transition: 0.5s all;
  transition: 0.5s all;
  vertical-align: bottom;
}
@media (min-width: 992px) {
.widget-button {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      vertical-align: auto;
}
}
.widget-button:hover {
    color: #ffffff;
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
}
.widget-button:hover .widget-button-icon__path {
      fill: #ffffff;
}
.widget-button--inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.widget-button-icon {
  margin-right: 12px;
}
.widget-button-icon__path {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.widget-button--colored-hover:hover {
  background: linear-gradient(89.84deg, #4C66BE 0.03%, #E84086 99.97%);
  color: #ffffff;
  border: 1px transparent;
}

.widget__song {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 16px;
}
.widget__song-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 16px;
}
@media (min-width: 576px) {
.widget__song-image {
      width: 60px;
      height: 60px;
}
}
.widget__song-info {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  text-align: left;
  min-width: 0;
}
.widget__song-info__artist-short {
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 300;
}
.widget__song-info__artist {
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 11px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 700;
}
@media (min-width: 576px) {
.widget__song-info__artist {
      font-size: 16px;
      line-height: 19px;
}
}
.widget__song-info__title {
  font-size: 12px;
  line-height: 14px;
  color: #444444;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media (min-width: 576px) {
.widget__song-info__title {
      font-size: 14px;
      line-height: 17px;
}
}
.widget__song-time {
  font-size: 10px;
  line-height: 12px;
  color: #888888;
  margin-left: 16px;
  position: relative;
}
.widget__song-time__zoom {
  position: absolute;
  cursor: pointer;
  top: -10px;
  left: -20px;
}

.search-widget {
  padding: 32px 16px;
}
.search-widget__title {
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  margin-bottom: 16px;
  font-weight: 700;
}
@media (min-width: 576px) {
.search-widget__title {
      font-size: 24px;
      line-height: 29px;
}
}
.search-widget__song-logo {
  margin-top: 32px;
  text-align: center;
}
.search-widget__song-logo-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 16px;
}
.search-widget__song-artist {
  font-size: 18px;
  line-height: 22px;
  text-align: center;
}
.search-widget__song-artist-title {
  font-size: 18px;
  line-height: 22px;
  font-weight: 300;
  margin-bottom: 16px;
  text-align: center;
}
.search-widget__song-search-title {
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 16px;
  text-align: center;
}
.search-widget__song-search-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  margin-bottom: 6px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
@media (min-width: 768px) {
.search-widget__song-search-wrapper {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
}
}
.search-widget__song-search-wrapper:last-child {
    margin-bottom: 0;
}
.search-widget__song-search-item {
  border: 1px solid #CACACA;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 48px;
  min-width: 130px;
  margin-bottom: 6px;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  color: #000000;
  text-decoration: none;
}
@media (min-width: 768px) {
.search-widget__song-search-item {
      margin-bottom: 0px;
}
}
@media (min-width: 992px) {
.search-widget__song-search-item {
      min-width: 146px;
}
}
.search-widget__song-search-item:hover {
    color: #ffffff;
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
}

.widget-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 16px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media (min-width: 992px) {
.widget-content {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
}
}
.widget-content__block-item {
  background: #FFFFFF;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 12px;
  padding: 32px 16px;
  color: #000000;
}
@media (min-width: 576px) {
.widget-content__block-item {
      padding: 32px;
}
}
.widget-content__block-title {
  text-align: center;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 16px;
  font-weight: 700;
}
@media (min-width: 576px) {
.widget-content__block-title {
      font-size: 24px;
      line-height: 29px;
}
}
.widget-content__block-title__text {
  color: #888888;
  cursor: pointer;
  margin-right: 8px;
  -webkit-transition: 0.5s all;
  transition: 0.5s all;
  font-weight: 500;
}
@media (min-width: 576px) {
.widget-content__block-title__text {
      margin-right: 16px;
}
}
.widget-content__block-title__text:last-child {
    margin-right: 0px;
}
.widget-content__block-title__text--active {
  color: #000000;
  font-weight: 700;
}
.widget-content__block-inside {
  max-height: 50vh;
  overflow-y: auto;
}
@media (min-width: 576px) {
.widget-content__block-inside {
      padding-right: 16px;
}
}
@media (min-width: 576px) {
.widget-content__block-inside::-webkit-scrollbar {
      width: 6px;
      background-color: #F5F5F5;
}
}
@media (min-width: 576px) {
.widget-content__block-inside::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: #CACACA;
}
}

.widget-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 16px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media (min-width: 992px) {
.widget-content {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
}
}
.widget-content__block-item {
  background: #FFFFFF;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 12px;
  padding: 32px 16px;
  color: #000000;
}
@media (min-width: 576px) {
.widget-content__block-item {
      padding: 32px;
}
}
.widget-content__block-title {
  text-align: center;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 16px;
  font-weight: 700;
}
@media (min-width: 576px) {
.widget-content__block-title {
      font-size: 24px;
      line-height: 29px;
}
}
.widget-content__block-title__text {
  color: #888888;
  cursor: pointer;
  margin-right: 8px;
  -webkit-transition: 0.5s all;
  transition: 0.5s all;
  font-weight: 500;
}
@media (min-width: 576px) {
.widget-content__block-title__text {
      margin-right: 16px;
}
}
.widget-content__block-title__text:last-child {
    margin-right: 0px;
}
.widget-content__block-title__text--active {
  color: #000000;
  font-weight: 700;
}
.widget-content__block-inside {
  max-height: 50vh;
  overflow-y: auto;
  padding-right: 16px;
}
.widget-content__block-inside {
  max-height: 50vh;
  overflow-y: auto;
  padding-right: 16px;
}
.widget-content__block-inside::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}
.widget-content__block-inside::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #CACACA;
}

.widget-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 16px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media (min-width: 992px) {
.widget-content {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
}
}
.widget-content__block-item {
  background: #FFFFFF;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 12px;
  padding: 32px 16px;
  color: #000000;
}
@media (min-width: 576px) {
.widget-content__block-item {
      padding: 32px;
}
}
.widget-content__block-title {
  text-align: center;
  font-size: 16px;
  line-height: 29px;
  margin-bottom: 16px;
  font-weight: 700;
}
@media (min-width: 576px) {
.widget-content__block-title {
      font-size: 24px;
}
}
.widget-content__block-title__text {
  color: #888888;
  cursor: pointer;
  margin-right: 8px;
  -webkit-transition: 0.5s all;
  transition: 0.5s all;
  font-weight: 500;
}
@media (min-width: 576px) {
.widget-content__block-title__text {
      margin-right: 16px;
}
}
.widget-content__block-title__text:last-child {
    margin-right: 0px;
}
.widget-content__block-title__text--active {
  color: #000000;
  font-weight: 700;
}
.widget-content__block-inside {
  max-height: 50vh;
  overflow-y: auto;
  padding-right: 16px;
}
.widget-content__block-inside::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}
.widget-content__block-inside::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #CACACA;
}

.order-loader {
  padding: 100px;
  text-align: center;
}
.order-widget {
  border-radius: 12px;
  padding: 32px 16px;
}
@media (min-width: 576px) {
.order-widget {
      padding: 32px;
}
}
.order-widget__title {
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  margin-bottom: 16px;
  font-weight: 700;
}
@media (min-width: 576px) {
.order-widget__title {
      font-size: 24px;
      line-height: 29px;
}
}
.order-widget__radio-logo {
  margin-top: 32px;
  text-align: center;
  margin-bottom: 32px;
}
.order-widget__radio-logo__image {
  width: 120px;
  height: 120px;
  border-radius: 12px;
}
.order-widget__share-text {
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  margin-bottom: 16px;
  font-weight: 300;
}
.order-widget__share-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.order-widget__share-button {
  width: 100px;
  height: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 6px;
  cursor: pointer;
  margin-right: 8px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  text-decoration: none;
}
.order-widget__share-button:last-child {
    margin-right: 0px;
}
.order-widget__share-button--vk {
  color: #5E81A9;
  border: 1px solid #5E81A9;
}
.order-widget__share-button--vk:hover {
    background: #5E81A9;
    color: #ffffff;
}
.order-widget__share-button--fb {
  color: #2C5DC6;
  border: 1px solid #2C5DC6;
}
.order-widget__share-button--fb:hover {
    background: #2C5DC6;
    color: #ffffff;
}
.order-widget__share-button--tw {
  color: #21A1F0;
  border: 1px solid #21A1F0;
}
.order-widget__share-button--tw:hover {
    background: #21A1F0;
    color: #ffffff;
}
.order-widget__share-button--ok {
  color: #ED8111;
  border: 1px solid #ED8111;
}
.order-widget__share-button--ok:hover {
    background: #ED8111;
    color: #ffffff;
}
.order-widget__song-logo {
  margin-top: 32px;
  text-align: center;
}
.order-widget__song-logo-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 16px;
}
.order-widget__song-artist {
  font-size: 18px;
  line-height: 22px;
  text-align: center;
}
.order-widget__song-artist-title {
  font-size: 18px;
  line-height: 22px;
  font-weight: 300;
  margin-bottom: 16px;
  text-align: center;
}
.order-widget__song-search-title {
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 16px;
  text-align: center;
}
.order-widget__song-search-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  margin-bottom: 6px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
@media (min-width: 768px) {
.order-widget__song-search-wrapper {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
}
}
.order-widget__song-search-wrapper:last-child {
    margin-bottom: 0;
}
.order-widget__song-search-item {
  border: 1px solid #CACACA;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 48px;
  min-width: 130px;
  margin-bottom: 6px;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  color: #000000;
  text-decoration: none;
}
@media (min-width: 768px) {
.order-widget__song-search-item {
      margin-bottom: 0px;
}
}
@media (min-width: 992px) {
.order-widget__song-search-item {
      min-width: 146px;
}
}
.order-widget__song-search-item:hover {
    color: #ffffff;
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
}
.order-widget__search-row {
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  min-width: 520px;
}
@media (min-width: 576px) {
.order-widget__search-row {
      font-size: 16px;
      line-height: 19px;
}
}
.order-widget__search-row:last-child {
    margin-bottom: 0;
}
.order-widget__search-row__item {
  font-weight: 300;
  cursor: pointer;
  padding: 3px;
}
.order-widget__search-row__item:hover {
    color: var(--primary-color);
}
.order-widget__search-row__item:last-child {
    margin-right: 0px;
}
.order-widget__search-row__item--active {
  font-weight: 500;
}
.order-widget__input-block {
  margin-top: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 16px;
}
@media (min-width: 576px) {
.order-widget__input-block {
      margin-top: 28px;
      margin-bottom: 32px;
}
}
.order-widget__input-block input {
    font-size: 14px;
    font-family: "Museo Sans", sans-serif;
}
@media (min-width: 576px) {
.order-widget__input-block input {
        font-size: 16px;
}
}
.order-widget__input-block__text {
  border: 1px solid #DADADA;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 4px;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  height: 48px;
  padding: 0 18px;
}
.order-widget__input-block__button {
  height: 38px;
  border: 1px solid #DADADA;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 4px;
  min-width: 118px;
  text-align: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  cursor: pointer;
}
@media (min-width: 576px) {
.order-widget__input-block__button {
      height: 48px;
}
}
.order-widget__input-block__button:hover {
    color: #ffffff;
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
}
.order-widget__results-block {
  margin-bottom: 16px;
}
.order-widget__results-block-title {
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 17px;
}
@media (min-width: 576px) {
.order-widget__results-block-title {
      font-size: 16px;
      line-height: 19px;
}
}
.order-widget__results-block-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  line-height: 17px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (min-width: 576px) {
.order-widget__results-block-item {
      font-size: 16px;
      line-height: 19px;
}
}
.order-widget__results-block-item:hover {
    color: var(--primary-color);
}
.order-widget__results-block-item-song {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  margin-bottom: 8px;
  font-weight: 300;
  padding-right: 12px;
}
.order-widget__results-block-item-button {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-width: 42px;
  height: 20px;
  border: 1px solid #DADADA;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 4px;
  font-size: 12px;
  line-height: 15px;
  color: #888888;
  cursor: pointer;
  -webkit-transition: 0.5s all;
  transition: 0.5s all;
  position: relative;
}
@media (min-width: 576px) {
.order-widget__results-block-item-button {
      width: 80px;
      height: 24px;
      font-size: 14px;
      line-height: 17px;
}
}
.order-widget__results-block-item-button:hover {
    color: #ffffff;
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
}
.order-widget__footer-logo {
  margin: 64px auto 0 auto;
  display: block;
  width: 80px;
  height: 80px;
  border-radius: 8px;
}
.order-widget__comment-block textarea {
  font-family: "Museo Sans" , sans-serif;
  font-size: 14px;
}
@media (min-width: 576px) {
.order-widget__comment-block textarea {
      font-size: 16px;
}
}
.order-widget__comment-block-label {
  text-align: center;
  font-weight: 300;
  margin-bottom: 12px;
}
.order-widget__comment-block-text {
  border: 1px solid #DADADA;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 4px;
  padding: 18px;
  height: 90px;
  width: 100%;
}
.order-widget__comment-block-button-block {
  margin-top: 16px;
  text-align: center;
}
.order-widget__comment-block-info {
  text-align: center;
  padding-top: 12px;
  padding-bottom: 12px;
}
.order-widget__input-cancel {
  cursor: pointer;
  font-weight: 300;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.order-widget__input-cancel:hover {
    color: var(--primary-color);
}
.order-widget__scrollable-body {
  max-height: 260px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 16px;
}
.order-widget__scrollable-body::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}
.order-widget__scrollable-body::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #CACACA;
}
.order-widget-horizontal-scroll {
  overflow: auto;
}

.widget-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 16px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media (min-width: 992px) {
.widget-content {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
}
}
.widget-content__block-item {
  background: #FFFFFF;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 12px;
  padding: 32px 16px;
  color: #000000;
}
@media (min-width: 576px) {
.widget-content__block-item {
      padding: 32px;
}
}
.widget-content__block-title {
  text-align: center;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 16px;
  font-weight: 700;
}
@media (min-width: 576px) {
.widget-content__block-title {
      font-size: 24px;
      line-height: 29px;
}
}
.widget-content__block-title__text {
  color: #888888;
  cursor: pointer;
  margin-right: 8px;
  -webkit-transition: 0.5s all;
  transition: 0.5s all;
  font-weight: 500;
}
@media (min-width: 576px) {
.widget-content__block-title__text {
      margin-right: 16px;
}
}
.widget-content__block-title__text:last-child {
    margin-right: 0px;
}
.widget-content__block-title__text--active {
  color: #000000;
  font-weight: 700;
}
.widget-content__block-inside {
  max-height: 50vh;
  overflow-y: auto;
  padding-right: 16px;
}
.widget-content__block-inside::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}
.widget-content__block-inside::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #CACACA;
}

.widget-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 16px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media (min-width: 992px) {
.widget-content {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
}
}
.widget-content__block-item {
  background: #FFFFFF;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 12px;
  padding: 32px 16px;
  color: #000000;
}
@media (min-width: 576px) {
.widget-content__block-item {
      padding: 32px;
}
}
.widget-content__block-title {
  text-align: center;
  font-size: 16px;
  line-height: 29px;
  margin-bottom: 16px;
  font-weight: 700;
}
@media (min-width: 576px) {
.widget-content__block-title {
      font-size: 18px;
}
}
.widget-content__block-title__text {
  color: #888888;
  cursor: pointer;
  margin-right: 8px;
  -webkit-transition: 0.5s all;
  transition: 0.5s all;
  font-weight: 500;
}
@media (min-width: 576px) {
.widget-content__block-title__text {
      margin-right: 16px;
}
}
.widget-content__block-title__text:last-child {
    margin-right: 0px;
}
.widget-content__block-title__text--active {
  color: #000000;
  font-weight: 700;
}
.widget-content__block-inside {
  text-align: center;
  max-height: 50vh;
  overflow-y: auto;
  padding-right: 16px;
}
.widget-content__block-inside::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}
.widget-content__block-inside::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #CACACA;
}
.widget__footer-logo {
  margin: 64px auto 0 auto;
  display: block;
  width: 80px;
  height: 80px;
  border-radius: 8px;
}

.widget-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 16px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media (min-width: 992px) {
.widget-content {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
}
}
.widget-content__block-item {
  background: #FFFFFF;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 12px;
  padding: 32px 16px;
  color: #000000;
}
@media (min-width: 576px) {
.widget-content__block-item {
      padding: 32px;
}
}
.widget-content__block-title {
  text-align: center;
  font-size: 16px;
  line-height: 29px;
  margin-bottom: 16px;
  font-weight: 700;
}
@media (min-width: 576px) {
.widget-content__block-title {
      font-size: 24px;
}
}
.widget-content__block-title__text {
  color: #888888;
  cursor: pointer;
  margin-right: 8px;
  -webkit-transition: 0.5s all;
  transition: 0.5s all;
  font-weight: 500;
}
@media (min-width: 576px) {
.widget-content__block-title__text {
      margin-right: 16px;
}
}
.widget-content__block-title__text:last-child {
    margin-right: 0px;
}
.widget-content__block-title__text--active {
  color: #000000;
  font-weight: 700;
}
.widget-content__block-inside {
  text-align: center;
  max-height: 50vh;
  overflow-y: auto;
  padding-right: 16px;
}
.widget-content__block-inside::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}
.widget-content__block-inside::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #CACACA;
}
.widget__footer-logo {
  margin: 64px auto 0 auto;
  display: block;
  width: 80px;
  height: 80px;
  border-radius: 8px;
}

.widget__content {
  font-family: "Museo Sans", sans-serif;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.widget__content-item {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-shadow: 0px 0px 64px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 0px 64px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  background: #FFFFFF;
  margin: 12px;
  width: 95%;
  max-width: 700px;
  max-height: 80%;
  position: relative;
}
@media (min-width: 576px) {
.widget__content-item {
      width: 75%;
}
}
@font-face {
  font-family: 'Museo Sans';
  src: url(/static/fonts/MuseoSansCyrl-300.eot);
  src: url(/static/fonts/MuseoSansCyrl-300.eot?#iefix) format("embedded-opentype"), url(/static/fonts/MuseoSansCyrl-300.woff) format("woff"), url(/static/fonts/MuseoSansCyrl-300.ttf) format("truetype");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Museo Sans';
  src: url(/static/fonts/MuseoSansCyrl-500.eot);
  src: url(/static/fonts/MuseoSansCyrl-500.eot?#iefix) format("embedded-opentype"), url(/static/fonts/MuseoSansCyrl-500.woff) format("woff"), url(/static/fonts/MuseoSansCyrl-500.ttf) format("truetype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Museo Sans';
  src: url(/static/fonts/MuseoSansCyrl-700.eot);
  src: url(/static/fonts/MuseoSansCyrl-700.eot?#iefix) format("embedded-opentype"), url(/static/fonts/MuseoSansCyrl-700.woff) format("woff"), url(/static/fonts/MuseoSansCyrl-700.ttf) format("truetype");
  font-weight: 700;
  font-style: normal; }
